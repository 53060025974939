/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import useStore from '+store';

import defaultMerchantLogo from '+assets/img/dashboard/biz-avatar.png';
import InfoSymbol from '+assets/img/dashboard/info-symbol.png';
import Loader from '+assets/img/dashboard/kpy-loader.svg';
import WarningIcon from '+assets/img/dashboard/warning-info-rounded.svg';

import './index.scss';

import { Logger } from '+utils';

const DesktopNav = ({ toggleEnvMode, routes, isUpdatingEnv, userRole }) => {
  const queryClient = useQueryClient();
  const MERCHANT_ENV = useStore(store => store.merchantEnv);
  const defaultMerchant = useStore(store => store.defaultMerchant);
  const kycData = queryClient.getQueryData('MERCHANT_KYC');
  const status = kycData?.compliance?.status;
  const flaggedCategories = kycData?.compliance?.flagged_categories;
  const [complianceStatus, setComplianceStatus] = useState('');
  const [flaggedKycData, setFlaggedKycData] = useState('');
  const tooltipRef = useRef(null);

  const filterRoutes = (pathnames: string[]) =>
    routes.base?.filter((route: { props: { children: { props: { to: { pathname: string | string[] } } } } }) =>
      pathnames.some(pathname => route.props.children.props.to.pathname.includes(pathname))
    );

  const checkStatus = Boolean(kycData?.bvn_required && (userRole === 'Owner' || userRole === 'Administrator'));

  const homePathnames = ['balances'];
  const transactionPathnames = ['pay-ins', 'payouts', 'settlements', 'disputes'];
  const toolsPathnames = ['payment-links', 'virtual-accounts', 'issuing', 'bulk-payout', 'identity'];
  const accountPathnames = ['audit-logs'];

  const homeNavArr = filterRoutes(homePathnames);
  const toolsNavArr = filterRoutes(toolsPathnames);
  const transactionsNavArr = filterRoutes(transactionPathnames);
  const accountNavArr = filterRoutes(accountPathnames);

  const settingsNavArr = routes.base?.find(route => route.props.children.props.to.pathname === '/dashboard/settings');
  if (settingsNavArr !== undefined) {
    settingsNavArr.props.children.props.to.pathname = '/dashboard/settings/general';
    accountNavArr.push(settingsNavArr);
  }

  useEffect(() => {
    setComplianceStatus(status);
    setFlaggedKycData(flaggedCategories);
  }, [status, flaggedCategories]);

  const handleTooltip = val => {
    if (val) {
      tooltipRef.current.style.display = 'block';
    } else {
      tooltipRef.current.style.display = 'none';
    }
  };

  return (
    <div className="menu-w color-scheme-light menu-position-side menu-side-left menu-layout-compact sub-menu-style-over sub-menu-color-bright selected-menu-color-light menu-activated-on-hover menu-has-selected-link">
      <div className="logged-user-w avatar-inline">
        <div className="logged-user-i">
          <div className="avatar-w" style={{ border: '1px solid #7447fd' }}>
            <img alt="Merchant logo" src={defaultMerchantLogo} />
          </div>
          <div className="logged-user-info-w">
            <div className="logged-user-name">{defaultMerchant?.name}</div>
            <div className="logged-user-role">ID PTS{defaultMerchant?.id}</div>
          </div>
        </div>
      </div>

      <ul className="main-menu">
        <li className={location.pathname === '/dashboard/home' ? 'selected' : ''}>
          <Link to="/dashboard/home">
            <div className="icon-w">
              <div className="phosphor-icon phosphor-icon-house" />
            </div>
            <span style={{ flex: 0 }}>Home</span>
            {checkStatus && <img src={WarningIcon} alt="bvn-link" style={{ marginLeft: '12px', width: '15px' }} />}
          </Link>
        </li>
        {homeNavArr?.map(route => route)}
        {/* {toolsNavArr?.length > 0 && (
          <li className="sub-header">
            <span>Tools</span>
          </li>
        )} */}
        {toolsNavArr?.map(route => route)}
        {/* {transactionsNavArr?.length > 0 && (
          <li className="sub-header">
            <span>Transactions</span>
          </li>
        )} */}
        {transactionsNavArr?.map(route => route)}
        {/* {accountNavArr?.length > 0 && (
          <li className="sub-header">
            <span>Account</span>
          </li>
        )} */}
        {accountNavArr?.map(route => route)}
        {/* <li>
          <a href="mailto:support@bvnkng.com" target="_blank" rel="noopener noreferrer">
            <div className="icon-w">
              <div className="phosphor-icon phosphor-icon-headset" />
            </div>
            <span>Support</span>
          </a>
        </li> */}

        <li className="support-list">
        {/* <a href="mailto:support@bvnkng.com" className="support_link" target="_blank" rel="noopener noreferrer">
          <div className="icon-w">
            <div className="phosphor-icon phosphor-icon-headset" />
          </div>
          <span>Support</span>
        </a> */}
        </li>
        {/* <li style={{ marginBottom: '20px' }}>
          <a href="https://developers.bvnkng.com" target="_blank" rel="noopener noreferrer">
            <div className="icon-w">
              <div className="phosphor-icon phosphor-icon-filecode" />
            </div>
            <span>Documentation</span>
          </a>
        </li> */}
        <li>
          {/* {MERCHANT_ENV === 'test' && <p className="test-data-indicator">Currently viewing test data</p>} */}
          <div className="floated-colors-btn second-floated-btn --lg" id="env-toggle">
            <div
              aria-label="Environment toggle"
              id="env-toggler"
              className={`os-toggler-w ${MERCHANT_ENV === 'live' ? 'on' : ''}`}
              onClick={toggleEnvMode}
              role="button"
              onKeyDown={toggleEnvMode}
              tabIndex={0}
            >
              {!isUpdatingEnv ? (
                <div className="os-toggler-i">
                  <div className="os-toggler-pill" />
                </div>
              ) : (
                <img src={Loader} alt="Loader" aria-hidden className="live-button--loader" />
              )}
            </div>
            <span>
              Live Mode
              <img
                onMouseOver={() => handleTooltip(true)}
                onFocus={() => handleTooltip(true)}
                onMouseLeave={() => handleTooltip(false)}
                onBlur={() => handleTooltip(false)}
                alt="What's this?"
                src={InfoSymbol}
              />
              <p
                ref={tooltipRef}
                className={`tip info-box 
              ${complianceStatus === 'verified' && 'verifiedInfoBox'} 
              ${complianceStatus === 'unverified' && 'unVerifiedInfoBox'}`}
              >
                {!complianceStatus ||
                  (complianceStatus === 'unverified' && !flaggedKycData && (
                    <>
                      <strong>You are currently viewing test data.</strong>&nbsp;Live mode will be available once your account is verified.
                    </>
                  ))}

                {complianceStatus === 'unverified' && flaggedKycData?.length > 0 && (
                  <>
                    <strong>You are currently viewing test data.</strong>&nbsp;Switch to Live mode to see live data.
                  </>
                )}

                {complianceStatus === 'ready' && (
                  <>
                    <strong>With your account verified</strong>&nbsp;you can now switch to Live mode.
                  </>
                )}

                {complianceStatus === 'verified' && MERCHANT_ENV === 'live' && <strong>You are viewing live data.</strong>}

                {complianceStatus === 'verified' && MERCHANT_ENV === 'test' && <strong>You are viewing test data.</strong>}
              </p>
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default DesktopNav;
