/* eslint-disable no-nested-ternary */
import { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import { ScrollToTopSection } from '+containers/Shared/ScrollToTop';
import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import useStore from '+store';
import { capitalizeRemovedash, formatAmount, formatStatus, history, logError } from '+utils';

import {
  ActiveTabType,
  AdditionalInfoType,
  DataType,
  EventType,
  PaymentAmountDetailsType,
  PaymentChannelsType,
  RefundsAndChargebackBreakdownType,
  TransactionBreakdownType,
  TransactionT
} from '../../../types/transaction-types';
import CreateRefundsModal from '../Shared/CreateRefundsModal';
import DeclineChargeBackModal from '../Shared/DeclineChargeBackModal';
import LoadingPlaceholder from '../Shared/LoadingPlaceholder';
import Modal from '../Shared/Modal';
import ReceiptModal, { IReceiptData } from '../Shared/ReceiptTemplate';
import ToolTip from '../Shared/Tooltip';
import IssuanceChargebackModal from './components/IssuanceChargebackModal';
import Webhooks from './components/Webhooks/Webhooks';
import { chargebacksTrxnMessage, getTransactionUtilities, switchDetailsHeading, switchTrxnMessage } from './data';

import InfoIcon from '+assets/img/dashboard/information-button.svg';
import OverpaymentSVG from '+assets/img/dashboard/overpayment.svg';
import UnderpaymentSVG from '+assets/img/dashboard/underpayment.svg';

import './index.scss';

const api: APIRequest = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

function TransactionDetails() {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation<{ pathname: string }>();
  const defaultMerchant = useStore((store: any) => store.defaultMerchant);
  const MERCHANT_ENV = useStore(store => store.merchantEnv);
  const permissions = useStore((state: any) => state.permissions);
  const transactionType: TransactionT = pathname.split('/').slice(-2)[0] as TransactionT;

  console.log(transactionType, 'TYPE');
  const getFormattedStatus = () => {
    if (transactionType === 'funding-deposit') {
      return formatStatus(status);
    }
    return status;
  };

  const switchAPI: Record<string, () => void> = {
    'pay-ins': () => api.getSingleTransaction('payins', id),
    payouts: () => api.getSingleTransaction('payouts', id),
    refunds: () => api.getSingleRefund(id),
    'card-transactions': () => api.getSingleIssuedCardTransaction(id),
    chargebacks: () => api.getSingleChargeBack(id),
    'issued-card-chargebacks': () => api.fetchSingleIssuedCardChargeback(id),
    'funding-deposit': () => api.fetchFundingDeposits({ id })
  };

  const initialEventState: EventType = {
    extraPaymentTabs: ['Refunds', 'Reversals', 'Chargebacks'],
    activeTab: 'Refunds',
    refundsModal: false,
    respondChargebackModal: false,
    IssuanceChargebackModalIsVisible: false,
    approve: false,
    reversalsLength: 0,
    refundsLength: 0,
    chargebacksLength: 0,
    showReceiptModal: false,
    txReceiptData: {} as IReceiptData,
    paymentType: false
  };

  const eventStateReducer = (prevState: EventType, newState: EventUpdatePayloadType): EventType => {
    return { ...prevState, ...newState };
  };

  const { feedbackInit } = useFeedbackHandler();
  const [event, updateEvent] = useReducer(eventStateReducer, initialEventState);

  // eslint-disable-next-line prefer-const
  let { data, isLoading, refetch } = useQuery(`TRANSACTION_DETAILS_${id}`, switchAPI[transactionType], {
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
        type: 'danger'
      });
      history.goBack();
    }
  });

  data = data?.data?.data[0] || data;

  const {
    reference,
    unique_reference: uniqueReference,
    amount,
    channel,
    amount_charged: amountCharged,
    amount_collected: amountCollected,
    meta,
    payment_reversals: reversals,
    currency,
    source,
    payment,
    status
  } = (data || {}) as DataType;

  useEffect(() => {
    if (reversals) {
      const isActiveTab: Record<ActiveTabType, () => void> = {
        refund: () => updateEvent({ activeTab: 'Refunds' }),
        underpayment: () => updateEvent({ activeTab: 'Reversals' }),
        overpayment: () => updateEvent({ activeTab: 'Reversals' }),
        chargeback: () => updateEvent({ activeTab: 'Chargebacks' })
      };

      const rev: ActiveTabType = reversals[0]?.type;
      isActiveTab[rev]?.();
    }
  }, [reversals]);

  const getTrxnStatusMessage = () => {
    const formattedStatus = getFormattedStatus();
    if (!formattedStatus) return switchTrxnMessage.processing;

    if (['processing', 'failed', 'pending', 'manual'].includes(formattedStatus?.toLowerCase()) && transactionType === 'refunds')
      return switchTrxnMessage.processing;

    if (transactionType === 'issued-card-chargebacks')
      return chargebacksTrxnMessage[formattedStatus as keyof typeof chargebacksTrxnMessage];

    return switchTrxnMessage[formattedStatus];
  };

  const trxnMessage = getTrxnStatusMessage();

  const [
    {
      refundsAndChargebackBreakdown,
      paymentChannels,
      virtualAccount,
      refundsAndReversal,
      renderIssuanceChargebackHistory,
      renderIssuanceChargebackDocs,
      transactionBreakdown,
      morePaymentDetails,
      additionalInfo,
      paymentChannelInfo,
      actionButtons,
      paymentTypesDetails
    },
    setTransactionUtilities
  ] = useState(
    getTransactionUtilities(
      { ...(data! as DataType), transactionType, id, defaultMerchant, permissions, status: getFormattedStatus() },
      { event, updateEvent }
    )
  );

  useEffect(() => {
    setTransactionUtilities(
      getTransactionUtilities(
        { ...(data! as DataType), transactionType, id, defaultMerchant, permissions, status: getFormattedStatus() },
        { event, updateEvent }
      )
    );
  }, [data, event]);

  return (
    <>
      <div className="transaction-details__comp">
        <div className="header-row">
          <div className="col-sm-12" style={{ padding: '0px' }}>
            <button type="button" className="btn btn-link goback-btn" onClick={() => history.goBack()}>
              <i className="os-icon os-icon-arrow-left7" />
              <span>Go Back</span>
            </button>
            {!isLoading && (
              <section className="invoice-heading">
                <div className="invoice-details">
                  <span className="amount-heading">
                    {['refunds', 'chargebacks'].includes(transactionType) ? (
                      reference?.toUpperCase()
                    ) : (
                      <>
                        {formatAmount(amountCharged || amountCollected || amount || '0.00')}
                        <span>{currency} </span>
                      </>
                    )}
                  </span>
                  <div className="invoice-date">
                    <span
                      style={{
                        color: trxnMessage?.color,
                        backgroundColor: trxnMessage?.backgroundColor
                      }}
                    >
                      {trxnMessage?.name}
                    </span>
                  </div>
                </div>

                {/* <div className="action-btns-container">
                  {actionButtons.map(({ label, onClick, leftIcon = null, rightIcon, color, ...btnProps }) => (
                    <button key={label} className={`btn btn-${color || 'secondary'}`} type="button" onClick={onClick} {...btnProps}>
                      {leftIcon && <span className="mr-2">{leftIcon}</span>}
                      {label}
                      {rightIcon && <span className="ml-2">{rightIcon}</span>}
                    </button>
                  ))}
                </div> */}
              </section>
            )}
          </div>
        </div>
        <div className="element-box transaction-details-container">
          <section className="trxn-information">
            {isLoading ? (
              <LoadingPlaceholder type="text" content={4} />
            ) : (
              <article>
                <ul className="trxn-breakdown-list">
                  {!['refunds', 'chargebacks'].includes(transactionType)
                    ? Object.keys(transactionBreakdown).map(item => {
                        if (
                          !transactionBreakdown[item as keyof TransactionBreakdownType] ||
                          transactionBreakdown[item as keyof TransactionBreakdownType] === ' '
                        )
                          return null;

                        if (item === 'fee') {
                          return null;
                        } else {
                          return (
                            <li key={item}>
                              <p>
                                {item === 'transaction_id' && 'Transaction ID'}
                                {item !== 'transaction_id' && capitalizeRemovedash(item)}
                                {item === 'net_amount' && (
                                  <ToolTip type="net_amount" image={InfoIcon} message={<>This is the amount less fees</>} />
                                )}
                                {
                                  item === 'fee' && null
                                  // <ToolTip
                                  //   image={InfoIcon}
                                  //   message={
                                  //     <em>
                                  //       {' '}
                                  //       Fees <br /> Total charges incurred while processesing this transaction.
                                  //     </em>
                                  //   }
                                  // />
                                }
                              </p>
                              <p>{transactionBreakdown[item as keyof TransactionBreakdownType]}</p>
                            </li>
                          );
                        }
                      })
                    : Object.keys(refundsAndChargebackBreakdown).map(item => {
                        if (
                          !refundsAndChargebackBreakdown[item as keyof RefundsAndChargebackBreakdownType] ||
                          refundsAndChargebackBreakdown[item as keyof RefundsAndChargebackBreakdownType] === ' '
                        )
                          return null;
                        return (
                          <li key={item}>
                            <p>
                              {item === 'transaction_id' && 'Transaction ID'}
                              {item !== 'transaction_id' && capitalizeRemovedash(item)}
                            </p>
                            <p>{refundsAndChargebackBreakdown[item as keyof RefundsAndChargebackBreakdownType]}</p>
                          </li>
                        );
                      })}
                </ul>
              </article>
            )}
          </section>
        </div>
      </div>
      <div className="transaction-details__comp_2">
        <div className="transaction-details-container-2">
          <section className="customer-information">
            {isLoading ? (
              <LoadingPlaceholder type="text" content={4} />
            ) : (
              <article>
                <ul>
                  <li>
                    <div
                      className="section-heading more-trxn-heading"
                      style={{
                        fontSize: '18px',
                        marginTop: '30px',
                        marginBottom: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        color: '#4e555b'
                      }}
                    >
                      {switchDetailsHeading[transactionType]} Details
                      {/* {transactionType === 'pay-ins' && (
                        <span className="amount-breakdown" onClick={() => updateEvent({ paymentType: true })}>
                          <i className="os-icon os-icon-arrow-up-right" /> View Amount Breakdown
                        </span>
                      )} */}
                    </div>
                    {meta?.payment_event_action?.event && (
                      <div className="overpayment-underpayment-info">
                        <img src={meta?.payment_event_action?.event === 'overpayment' ? OverpaymentSVG : UnderpaymentSVG} alt="" />
                        <span>
                          There is an <strong>{meta?.payment_event_action?.event}</strong> on this transaction.
                        </span>
                      </div>
                    )}
                    {Object.keys(morePaymentDetails).map(item => {
                      if (item === 'description' || item == 'fee_bearer') {
                        return null;
                      } else {
                        return (
                          <p key={item}>
                            <span>
                              {item === 'rnn' && (
                                <>
                                  RNN <ToolTip image={InfoIcon} type="rnn" message="Reference Retrieval Number" />
                                </>
                              )}
                              {item === 'stan' && (
                                <>
                                  STAN <ToolTip image={InfoIcon} type="stan" message="System Trace Audit Number" />
                                </>
                              )}
                              {!['stan', 'rnn'].includes(item) && capitalizeRemovedash(item)}
                              {item === 'approval_code' && <ToolTip image={InfoIcon} message="Also known as ‘Acquirer code" />}
                              {item === 'cross-currency_status' && (
                                <ToolTip
                                  image={InfoIcon}
                                  message="This defines whether a transaction is performed in the issued virtual card currency or not."
                                />
                              )}
                              {item === 'expected_resolution_date' && (
                                <ToolTip image={InfoIcon} message="This chargeback will be resolved on or before this date" />
                              )}
                            </span>
                            <span>{morePaymentDetails[item]}</span>
                          </p>
                        );
                      }
                    })}
                  </li>

                  <li>
                    {paymentChannelInfo?.title ? (
                      <p className="section-heading" id="section-title">
                        {paymentChannelInfo?.title}
                      </p>
                    ) : null}
                    {Object.keys(paymentChannelInfo?.data || {}).map(item => (
                      <p key={item}>
                        {['bank_account', 'card', 'wallet', 'mobile_money'].includes(item) ? (
                          paymentChannels(item as PaymentChannelsType)
                        ) : (
                          <>
                            <span>{capitalizeRemovedash(item)}</span>
                            <span>{paymentChannelInfo?.data?.[item as keyof AdditionalInfoType['data']]}</span>
                          </>
                        )}
                      </p>
                    ))}
                  </li>

                  {/* <li>
                    {additionalInfo?.title ? (
                      <p className="section-heading" id="section-title">
                        {additionalInfo?.title}
                      </p>
                    ) : null}
                    {Object.keys(additionalInfo?.data || {}).map(item => (
                      <p key={item}>
                        {['bank_account', 'card', 'wallet', 'mobile_money'].includes(item) ? (
                          paymentChannels(item as PaymentChannelsType)
                        ) : (
                          <>
                            <span>{capitalizeRemovedash(item)}</span>
                            <span>{additionalInfo?.data?.[item as keyof AdditionalInfoType['data']]}</span>
                          </>
                        )}
                      </p>
                    ))}
                  </li> */}

                  {/* {source?.virtual_bank_account && <li>{virtualAccount()}</li>} */}
                  {/* {['pay-ins', 'card-transactions'].includes(transactionType) && <li>{refundsAndReversal()}</li>} */}
                  <li>{transactionType === 'issued-card-chargebacks' && renderIssuanceChargebackHistory()}</li>
                  <li>{transactionType === 'issued-card-chargebacks' && renderIssuanceChargebackDocs()}</li>
                  {/* {['pay-ins', 'payouts'].includes(transactionType) && (
                    <li>
                      <Webhooks
                        paymentReference={payment?.reference}
                        reference={reference}
                        uniqueReference={uniqueReference}
                        channel={channel}
                        transactionStatus={status}
                        transactionType={transactionType}
                      />
                    </li>
                  )} */}
                </ul>
              </article>
            )}
          </section>
        </div>
      </div>
      {event.refundsModal && (
        <CreateRefundsModal
          visible={event.refundsModal}
          reference={payment?.reference}
          refetchRefund={refetch}
          currency={currency}
          close={() => updateEvent({ refundsModal: false })}
        />
      )}

      {event.paymentType && (
        <Modal
          close={() => updateEvent({ paymentType: false })}
          heading="Amount Breakdown"
          description={
            <p style={{ color: '#414F5F', fontWeight: 400 }}>
              This is a detailed breakdown of the amount on this transaction, including the individual components that contribute to the
              final value of the transaction.
            </p>
          }
          content={
            <>
              {meta?.payment_event_action?.event && (
                <div className="overpayment-underpayment-info">
                  <img
                    src={meta?.payment_event_action?.event === 'overpayment' ? OverpaymentSVG : UnderpaymentSVG}
                    alt={`${meta?.payment_event_action?.event} icon`}
                  />
                  <span>
                    There is an <strong>{meta?.payment_event_action?.event}</strong> on this transaction.
                  </span>
                </div>
              )}
              {Object.keys(paymentTypesDetails).map(item => (
                <p className="overpayment-underpayment-list" key={item}>
                  <span className="overpayment-underpayment-list-left-side">
                    {capitalizeRemovedash(item)}
                    {item === 'amount_expected' && (
                      <ToolTip
                        image={InfoIcon}
                        type="amount_expected"
                        message="This is the entire amount that the customer was supposed to pay."
                      />
                    )}
                    {item === 'amount_paid' && (
                      <ToolTip image={InfoIcon} type="amount_paid" message="This is the total amount paid (including fees)." />
                    )}
                    {item === 'amount_charged' && (
                      <ToolTip
                        image={InfoIcon}
                        type="amount_charged"
                        message="This is the total amount to be settled, excluding fees and any reserves."
                      />
                    )}

                    {item === 'fees' && <ToolTip image={InfoIcon} type="fees" message="This is the sum of the transaction fees." />}
                    {item === 'tax' && <ToolTip image={InfoIcon} type="tax" message="This is the sum of the tax deducted" />}
                  </span>
                  <span className="overpayment-underpayment-list-right-side">
                    {paymentTypesDetails[item as keyof PaymentAmountDetailsType]}
                  </span>
                </p>
              ))}
            </>
          }
          firstButtonText="Close"
          hideSecondButton
        />
      )}

      <DeclineChargeBackModal
        visible={event.respondChargebackModal}
        reference={reference}
        refetchChargeBack={refetch}
        currency={currency}
        approve={event.approve}
        amount={Number(amount)}
        close={() => updateEvent({ respondChargebackModal: false })}
      />

      {/* {transactionType !== 'funding-deposit' ? <section className="back-to-top">{ScrollToTopSection()}</section> : null} */}

      {event.showReceiptModal && <ReceiptModal data={event.txReceiptData} close={() => updateEvent({ showReceiptModal: false })} />}
    </>
  );
}

export default TransactionDetails;
