import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import useFeedbackHandler from '+hooks/feedbackHandler';
import APIRequest from '+services/api-services';
import { getDate, logError } from '+utils';

import Table from '../../Shared/Table';
import ReplaceBankAccount from './components/ReplaceBankAccount';
import UpdateSettlementDestination from './components/UpdateSettlementDestination';

import ChatSvg from '+assets/img/auth/chat.svg';

import './index.scss';

import useStore from '+store';

const api = new APIRequest();

function SettlementsSettings() {
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const availableCurrencies = useStore(state => state.availableCurrencies);
  const [bankDetails, setBankDetails] = useState([]);
  const kycStatus = queryClient.getQueryData('MERCHANT_KYC');
  const [bankAccountRejection, setBankAccountRejection] = useState({ rejected: false, reason: null });
  const [modalStates, setModalStates] = useState({
    Replace: true
  });

  const { data: config = {}, refetch: refetchConfig } = useQuery('SETTLEMENT_CONFIG', api.getSettlementConfig, {
    onError: error => {
      logError(error);
      feedbackInit({
        message: 'There has been an error getting your settlements settings',
        type: 'danger',
        action: {
          action: () => queryClient.invalidateQueries('SETTLEMENT_CONFIG'),
          name: 'Try again'
        }
      });
    }
  });

  const { isFetching: fetchAccount } = useQuery('SETTLEMENT_ACCOUNTS', api.getSettlementAccount, {
    onSuccess: data => {
      const response = data;
      const list = Object.values(response?.data || {})[0];
      for (let i = 0; i < list?.length; i++) {
        if (list[i].status === 'rejected') {
          setBankAccountRejection({
            rejected: true,
            reason: list[i].status_details?.[0]?.comment
          });
        }
      }
      setBankDetails(Object.values(response?.data || {}).flat());
    },
    onError: e => {
      const error = e.response?.data;
      logError(error?.message);
      feedbackInit({
        message: 'There has been an error getting settlement bank details',
        type: 'danger'
      });
    }
  });

  const formatSettlementAccountStatus = status => {
    switch (status) {
      case 'active':
        return {
          itemColor: '',
          accountNameColor: '#7447fd',
          label: 'Approved',
          bgColor: '#F9FBFD',
          statusColor: '#24B314'
        };
      case 'rejected':
        return {
          itemColor: 'rgba(62, 75, 91, 0.4)',
          accountNameColor: 'rgba(62, 75, 91, 0.4)',
          label: 'Rejected',
          bgColor: 'rgba(249, 251, 253, 0.4)',
          statusColor: '#F32345'
        };
      default:
        return {
          itemColor: 'rgba(62, 75, 91, 0.4)',
          accountNameColor: 'rgba(62, 75, 91, 0.4)',
          label: 'Pending',
          bgColor: 'rgba(249, 251, 253, 0.4)',
          statusColor: '#F9A000'
        };
    }
  };

  const settlementAccountTable = () => {
    return (
      <>
        {bankDetails?.map(each => {
          const { itemColor, label, statusColor, accountNameColor } = formatSettlementAccountStatus(each.status);
          return (
            <div className="div-table --teams-table --row" key={each?.id}>
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '180px'
                }}
              >
                <span
                  style={{
                    color: accountNameColor
                  }}
                >
                  {each?.account_details?.account_name}
                </span>
              </div>

              <div>
                <span style={{ color: itemColor }}>{each?.account_details?.account_number}</span>
              </div>

              <div>
                <span style={{ color: itemColor }}>{each?.bank?.name}</span>
              </div>

              <div className="settlement_status_div">
                <span style={{ color: statusColor }}>{label}</span>
              </div>
            </div>
          );
        })}
      </>
    );
  };

  const [isUpdateDestinationVisible, setisUpdateDestinationVisible] = useState(false);

  const switchDestination = {
    // disbursement_wallet: 'bvnkng Balance',
    settlement_account: 'Settlement Bank Account'
  };

  const settlementConfig = config?.data;

  // console.log(settlementConfig, 'SETTLEMENT CONFIG');
  return (
    <>
      <div className="element-box settlements__settings">
        <div className="element-box-heading">
          <div className="heading-box-mmd">
            <h4 className="form-header">Settlements</h4>
            <div className="form-desc no-underline">
              Funds collected from your customers can either be settled into your bvnkng Balance or your verified settlement account . You
              can get more details on these transactions on the <Link to="/dashboard/settlements">Settlements page.</Link>
            </div>
          </div>
        </div>
        <div className="settings-box">
          <div className="settings-group">
            <legend>
              <span />
            </legend>
            <div className="settings-item">
              <div className="settings-item-info">
                <h5 className="form-header">Settlement Destination</h5>
                <div className="info-desc">
                  Your settlement destination is where all your successful transactions will be settled. Currently, your settlements will be
                  deposited in the accounts listed below.
                </div>
              </div>
              <span className="settings-item-control">
                <button className="btn btn-primary" type="button" onClick={() => setisUpdateDestinationVisible(true)}>
                  Change
                </button>
              </span>
            </div>

            <div className="settlements__settings--dest-w">
              {Object.keys(settlementConfig || {})?.map(currency => {
                if (currency === availableCurrencies?.[0]) {
                  return (
                    <div key={currency}>
                      <span className="settlements__settings--dest-currency">
                        <span />
                        <span>{currency}</span>
                      </span>
                      <p>
                        Your {currency} Transactions will be settled into your{' '}
                        {/* <strong>{switchDestination[settlementConfig[currency].destination]}</strong> */}
                        <strong>{switchDestination.settlement_account}</strong>
                      </p>
                      {settlementConfig[currency]?.updated_at && (
                        <p className="settlements__settings--dest-updated">
                          Last updated: {getDate(settlementConfig[currency].updated_at)}
                        </p>
                      )}
                    </div>
                  );
                }
              })}
            </div>
            <legend>
              <span />
            </legend>

            <div className="settlements__accounts">
              <div className="settings-item">
                <div className="settlement-bank-details">
                  <div className="settings-item-info">
                    <div>
                      <h5 className="form-header">Settlement Bank Account</h5>
                      <div className="info-desc">This is your bank account into which you can receive settlements.</div>
                    </div>
                  </div>
                  {}
                  {kycStatus?.compliance?.status === 'verified' && (
                    <ReplaceBankAccount visible={modalStates.Replace} close={() => setModalStates({ ...modalStates, Replace: true })} />
                  )}
                </div>
                <Table
                  hasPagination={false}
                  header={false}
                  tableClassName="--teams-table"
                  headings={[
                    {
                      value: 'account name'
                    },
                    {
                      value: 'account number'
                    },
                    {
                      value: 'bank'
                    }
                  ]}
                  loading={fetchAccount}
                  emptyStateMessage={'No Settlement Bank Account set yet.'}
                  children={settlementAccountTable()}
                  background="#f5f6f6"
                />
              </div>

              {bankAccountRejection.rejected && bankAccountRejection.reason && (
                <div className="settlement-feedback">
                  <img src={ChatSvg} alt="kyc_feedback" />
                  <span>{bankAccountRejection.reason}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {isUpdateDestinationVisible && (
        <UpdateSettlementDestination
          visible={isUpdateDestinationVisible}
          closeModal={() => setisUpdateDestinationVisible(false)}
          updateSettings={refetchConfig}
          settlementConfig={settlementConfig}
        />
      )}
    </>
  );
}

export default SettlementsSettings;
